import React from "react";

function BookingIconSvg(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 850.4 850.4"
        fill="currentColor"
        // height="2rem"
        // width="2rem"
        id="Layer_1"
        {...props}
      >
        <style type="text/css">
          {`
            .st0 { stroke: #39377E; stroke-width: 0.5612; stroke-miterlimit: 10; }
            .st1 { fill: #0F1729; }
            .st2 { fill-rule: evenodd; clip-rule: evenodd; fill: #0F1729; }
            .st3 { fill: #FFFFFF; stroke: #020202; stroke-width: 6.0514; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 50; }
            .st4 { fill: #E1DFD8; stroke: #020202; stroke-width: 6.0514; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 50; }
          `}
        </style>
        <g>
          <g>
            <path
              className="st0"
              d="M536.2,308.6l-222-28.6c-18.9-2.4-35.8,7.3-37.7,21.8l-3.1,24.3l-1,7.8l9.9,2.4c5.7,1.4,9.6,5.8,9,10.3
              c-0.6,4.5-5.5,7.8-11.4,7.7l-10.2-0.2l-1,7.8l-3.1,24.3c-1.9,14.4,12,28.2,30.9,30.6l222,28.6c18.9,2.4,35.8-7.3,37.7-21.8
              l3.1-24.1l1.1-8.6l-11.1-1.8c-6.3-1-10.8-5.7-10.2-10.5c0.6-4.9,6.1-8.2,12.5-7.6l11.2,1l1.1-8.6l3.1-24.1
              C568.9,324.8,555.1,311,536.2,308.6z M552.2,361.8c-12.6-1.2-23.6,5.4-24.8,15.1c-1.2,9.7,7.7,18.9,20.2,20.9l-3.1,24.1
              c-1.2,9.5-12.3,15.9-24.8,14.3l-222-28.6c-12.5-1.6-21.6-10.6-20.3-20.1l3.1-24.3c11.6,0.2,21.4-6.2,22.6-15.3
              c1.2-9.1-6.7-17.7-18-20.5l3.1-24.3c1.2-9.5,12.3-15.9,24.8-14.3l222,28.6c12.5,1.6,21.6,10.6,20.3,20.1L552.2,361.8z"
            />
            <rect
              x="329.3"
              y="389.9"
              transform="matrix(0.1278 -0.9918 0.9918 0.1278 -96.6206 677.8389)"
              className="st0"
              width="15.6"
              height="7.8"
            />
            <rect
              x="332.8"
              y="362.8"
              transform="matrix(0.1278 -0.9918 0.9918 0.1278 -66.6112 657.6068)"
              className="st0"
              width="15.6"
              height="7.8"
            />
            <rect
              x="336.3"
              y="335.6"
              transform="matrix(0.1278 -0.9918 0.9918 0.1278 -36.6013 637.3743)"
              className="st0"
              width="15.6"
              height="7.8"
            />
            <rect
              x="339.8"
              y="308.4"
              transform="matrix(0.1278 -0.9918 0.9918 0.1278 -6.5944 617.1438)"
              className="st0"
              width="15.6"
              height="7.8"
            />
            <polygon
              className="st0"
              points="336.2,257.9 329,259.9 337.1,274.7 344.3,272.7"
            />
            <path
              className="st0"
              d="M582.2,288.8l-12.6-23l-4.5-8.2l-10.5,2.5c-6,1.4-12.7-1.1-15.2-5.7c-2.6-4.6,0-9.7,5.7-11.6l10.1-3.3
              l-4.5-8.2l-12.6-23c-7.6-13.8-27.9-21.1-45.3-16.2l-204.6,57.5c-17.4,4.9-25.5,20.1-17.9,33.9l0.5,0.8c2.5-2.8,5.5-5.3,8.9-7.5
              c-2.1-8,3.2-15.9,13.2-18.7l204.6-57.5c11.5-3.2,24.8,1.5,29.8,10.6l12.6,23c-11.3,3.7-16.4,13.8-11.3,23.1
              c5.1,9.3,18.4,14.2,30.2,11.4l12.6,23c3.8,6.9,1.6,14.4-4.8,19c2.8,2.3,5.2,4.9,7.2,7.6C584.4,311.6,588.2,299.7,582.2,288.8z"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M458.4,658.3c0,18.4-14.9,33.3-33.3,33.3c-18.4,0-33.3-14.9-33.3-33.3S406.6,625,425,625
              C443.4,625,458.4,639.9,458.4,658.3z"
            />
            <path
              className="st2"
              d="M332.8,58.3l1.6,0c0.3,0,0.5,0,0.8,0h179.6c0.3,0,0.5,0,0.8,0l1.6,0c0.8,0,1.6,0,2.3,0h5.5c0.5,0,1.1,0,1.6,0
              c2.9,0,5.4,0.1,7.8,0.3C600.7,63.4,653.3,116,658,182.2c0.3,4.8,0.3,10.1,0.3,17.3v399.3c0,27.3,0,49.8-1.5,68.1
              c-1.6,19-5,36.5-13.5,52.9c-12.6,24.4-32.5,44.2-56.9,56.9c-16.4,8.5-33.9,11.9-52.9,13.5c-18.3,1.5-40.8,1.5-68.1,1.5h-80.8
              c-27.3,0-49.8,0-68.1-1.5c-19-1.6-36.5-5-52.9-13.5c-24.4-12.6-44.2-32.5-56.9-56.9c-8.5-16.4-11.9-33.9-13.5-52.9
              c-1.5-18.3-1.5-40.9-1.5-68.1l0-399.3c0-7.2,0-12.5,0.3-17.3c4.7-66.2,57.4-118.8,123.5-123.5c2.4-0.2,5-0.3,7.8-0.3
              c0.5,0,1.1,0,1.6,0h5.5C331.3,58.3,332,58.3,332.8,58.3z M334.1,125c-9,0-11.7,0-13.8,0.2c-33.1,2.4-59.4,28.7-61.8,61.8
              c-0.1,2.1-0.2,4.8-0.2,13.8v396.6c0,29,0,48.8,1.3,64c1.2,14.8,3.5,22.4,6.2,27.7c6.3,12.2,16.3,22.1,28.4,28.4
              c5.3,2.7,12.9,5,27.7,6.2c15.2,1.3,35,1.3,64,1.3h78c29,0,48.8,0,64-1.3c14.8-1.2,22.4-3.5,27.7-6.2c12.2-6.3,22.1-16.3,28.4-28.4
              c2.7-5.3,5-12.9,6.2-27.7c1.3-15.2,1.3-35,1.3-64V200.7c0-9,0-11.7-0.2-13.8c-2.4-33.1-28.7-59.4-61.8-61.8
              c-2.1-0.1-4.8-0.2-13.8-0.2c-2.2,0-2.5,0-2.6,0c-4.3,0.2-8.3,2-11.3,5c-0.1,0.1-0.3,0.3-1.8,2l-16.3,18.1l-0.4,0.5
              c-0.2,0.2-0.6,0.6-1.1,1.1c-1,1-2.9,2.8-5.6,4.6c-3.2,2.1-6.7,3.7-10.4,4.6c-3.1,0.8-5.7,1-7.1,1.1c-0.7,0-1.3,0-1.5,0l-0.6,0
              h-64.3l-0.6,0c-0.3,0-0.9,0-1.5,0c-1.5-0.1-4-0.3-7.1-1.1c-3.7-1-7.2-2.5-10.4-4.6c-2.7-1.8-4.5-3.5-5.6-4.6
              c-0.5-0.5-0.9-0.9-1.1-1.1l-0.4-0.5L349.8,132c-1.5-1.7-1.7-1.8-1.8-2c-3-3.1-7-4.9-11.3-5C336.6,125,336.3,125,334.1,125z"
            />
          </g>
          <g id="Page-1">
            <g id="Hand-finger" transform="translate(1.000000, 1.000000)">
              <path
                id="Shape_4_"
                className="st3"
                d="M761.6,696.6l-2.1-2.4c-8.7-13.2,6.9-35.2,0.5-45.6l-49.7-81c-6.4-10.4-24.6-1.9-31,2
                l10.1,16.4l-10.1-16.4c0,0-10-10.9-32.2,10.8l13.8,22.5l-13.8-22.5c-0.7-0.2-12.3-4.9-23.3,1.9S610.5,601,610.5,601l14.6,23.9
                l-44.2-72c-6.1-9.9-19-12.5-29.2-6.3s-13.4,19.4-7.6,28.8l69,112.5l-16.3-26.6c-14.3,23-40.6,23.6-21.3,47.8
                c40.5,49.5,74.5,41,91.3,45.6"
              />
              <path
                id="Shape_5_"
                className="st4"
                d="M680.2,746.5l-14.7,9c-9.7,5.9-16.7,10.9-10.4,21.2l33.7,54.9c6.4,10.4,14.2,5.6,23.4-0.1
                l90.1-55.3c9.7-5.9,16.7-10.9,10.7-20.8l-33.7-54.9c-6.4-10.4-14.4-6-23.6-0.4l-15.6,9.6"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}

export default BookingIconSvg;

// src/components/Footer.jsx
import React from "react";
import styled from "styled-components";

// Styled container for the footer
export const FooterContainer = styled.footer`
  background: #2c3e50; // Dark background color
  color: #ecf0f1; // Light text color
  padding: 2em 0; // Padding for top and bottom
  text-align: center; // Centered text
  display: flex; // Flexbox layout
  flex-direction: column; // Column layout
  align-items: center; // Center items horizontally
`;

// Styled component for the address
const Address = styled.p`
  margin: 0.5em 0; // Margin for spacing
  font-size: 1em; // Font size
`;

// Styled component for the copyright text
const Copyright = styled.p`
  margin: 0.5em 0; // Margin for spacing
  font-size: 0.9em; // Font size
`;

// Styled component for a horizontal line
const Divider = styled.hr`
  width: 80%; // Width of the line
  border: 0; // No border
  border-top: 1px solid #7f8c8d; // Top border with color
  margin: 0em 0; // Margin for spacing
`;

// Footer functional component
const Footer = () => {
  return (
    <FooterContainer>
      <Address>
        Room A, 4th Floor, Sing Teck Factory Building, 44 Wong Chuk Hang Road,
        Hong Kong. Tel: +852-26633524
      </Address>
      <Divider />
      <Copyright>
        © 2002 - {new Date().getUTCFullYear()} DYNAMIC MANAGEMENT GROUP LIMITED.
        ALL RIGHTS RESERVED.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;

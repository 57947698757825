// src/GlobalStyle.js
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {}
  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: "Lato", sans-serif;
    color: #3a3a3a;
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  a {
    text-decoration: none;
  }

  @import url('https://fonts.googleapis.com/css?family=Lato:400,300');
`;

export default GlobalStyle;

// src/components/Images.jsx
import React from "react";
import styled from "styled-components";

// Container for the entire Images section
export const ImagesContainer = styled.section`
  padding-top: 4em;
`;

// List styling for images
export const ImagesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
`;

// Container for each image item
export const ImageItemContainer = styled.li`
  background: rgba(0, 0, 0, 0.35);
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: background 0.4s;

  &:hover {
    background: rgba(0, 0, 0, 0);
  }

  @media (min-width: 500px) {
    flex: 50%;
  }

  @media (min-width: 800px) {
    flex: 25%;
  }
`;

// Info overlay for each image item
export const ImageInfo = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;

  h3 {
    margin-bottom: 0.5em;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  h4 {
    margin-top: 0;
    font-weight: 300;
    letter-spacing: 1px;
  }
`;

// Image styling
export const Image = styled.img`
  position: relative;
  z-index: -1;
  width: 100%;
  height: auto;
`;

const ImageItem = ({ title, description, imageUrl }) => {
  return (
    <ImageItemContainer>
      <Image src={imageUrl} alt={`${title}, ${description}`} />
      <ImageInfo>
        <h3>{title}</h3>
        <h4>{description}</h4>
      </ImageInfo>
    </ImageItemContainer>
  );
};

const Images = () => {
  const imageItems = [
    {
      title: "QR CODE TICKETS",
      description: "Scan and Go",
      imageUrl: "/images/1.jpg",
    },
    {
      title: "Sporting Events",
      description: "Synthetic Ice Skating",
      imageUrl: "/images/2.jpg",
    },
    {
      title: "Sporting Events",
      description: "Synthetic Ice Skating",
      imageUrl: "/images/3.jpg",
    },
    {
      title: "WEB APPS",
      description: "Customed Web Apps",
      imageUrl: "/images/4.jpg",
    },
    {
      title: "Sporting Events",
      description: "Social Media Promotions",
      imageUrl: "/images/5.jpg",
    },
    {
      title: "Sporting Events",
      description: "Synthetic Ice Skating",
      imageUrl: "/images/6.jpg",
    },
    {
      title: "Sporting Events",
      description: "Synthetic Ice Skating",
      imageUrl: "/images/7.jpg",
    },
    {
      title: "Sporting Events",
      description: "Synthetic Ice Skating",
      imageUrl: "/images/8.jpg",
    },
  ];

  return (
    <ImagesContainer>
      <ImagesList>
        {imageItems.map((item, index) => (
          <ImageItem
            key={index}
            title={item.title}
            description={item.description}
            imageUrl={item.imageUrl}
          />
        ))}
      </ImagesList>
    </ImagesContainer>
  );
};

export default Images;

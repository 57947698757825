// src/components/Intro.jsx
import React from "react";
import styled from "styled-components";
import BookingIconSvg from "./svg/BookingIconSvg";

export const IntroContainer = styled.div`
  background: #eeebe4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4em 0;
`;

export const IntroSection = styled.div`
  background: #eeebe4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4em 0;
`;

export const CallToAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #28415e;
`;

export const LogoImage = styled.img`
  /* display: none; */
  margin-bottom: 1em;

  @media (min-width: 500px) {
    display: block;
    width: 200px;
  }
`;

export const ActionButton = styled.a`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  box-shadow: inset 0 0 0 2px #b79d55;
  color: #28415e;

  &:hover,
  &:focus {
    background: #b79d55;
    color: #fff;
  }
`;

const Intro = () => {
  return (
    <IntroSection>
      <CallToAction>
        <BookingIconSvg />
        <h1>Booking & Ticketing</h1>
        <p>Providing digital booking and ticketing services</p>
        <ActionButton
          href="https://wa.me/85294551355"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>contact us</span>
        </ActionButton>
      </CallToAction>
    </IntroSection>
  );
};

export default Intro;

// src/components/HeaderV2.jsx
import React from "react";
import styled from "styled-components";
import DynamicLogoSvg from "./svg/DynamicLogoSvg";

// Styled container for the header
export const HeaderContainerV2 = styled.header`
  background: #f8f9fa; // Light grey background for a modern look
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  padding: 1rem 0; // Padding for top and bottom
`;

// Styled container for the content within the header
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1170px;
  padding: 0 1rem; // Padding for left and right

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem; // Padding for all sides on smaller screens
  }
`;

// Styled component for the logo
export const Logo = styled.div`
  padding: 0 1rem;
  width: 300px;
  height: auto;

  svg {
    width: 100%; // Ensure the SVG scales properly
    height: auto;
  }
`;

// Styled component for the event title
export const EventTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: clamp(280px, 50%, 450px); // Responsive width
  width: 100%;
  line-height: 1.2; // Slightly increased line height for readability
  text-align: right;
  line-height: 0.3;
  padding: 0 1rem;

  @media (max-width: 600px) {
    text-align: center; // Center text on smaller screens
    padding-top: 2rem; // Top padding for spacing
  }

  h1 {
    margin: 0;
    font-size: clamp(1.5rem, 2.5vw, 2.5rem); // Responsive font size
    color: #343a40; // Dark grey color for text
  }
`;

// Header functional component
const Header = () => {
  return (
    <HeaderContainerV2>
      <Container>
        <Logo>
          <DynamicLogoSvg />
        </Logo>
        <EventTitle>
          <h1>DIGITAL SERVICES &</h1>
          <h2>BOOKING APP</h2>
          <h1>數碼服務</h1>
        </EventTitle>
      </Container>
    </HeaderContainerV2>
  );
};

export default Header;

// src/components/Details.jsx
import React from "react";
import styled from "styled-components";
import bookingSystemSvg from "./svg/others/qr-code-scan.svg";
import platformInterfaceSvg from "./svg/others/interface-control-svgrepo-com.svg"; // Update with the correct path
import backendServerSvg from "./svg/others/server-network-part-2-svgrepo-com.svg"; // Update with the correct path

// Styled container for the details section
export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4em 0;
  border-radius: 1rem;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

// Styled component for each article
export const Article = styled.article`
  background: #edf4f7;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0.85em 1em;
  padding: 1em;
  border-radius: 3px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 800px) {
    margin-bottom: 0;
  }

  h3 {
    color: #28415e;
  }

  p {
    margin: 0 0 1em;
    font-size: 0.95em;
    line-height: 24px;
    font-weight: 400;

    &:last-of-type {
      margin-bottom: 3em;
    }
  }
`;

// Styled component for the SVG wrapper
export const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;

  img {
    width: 200px; // Default size
    height: auto;

    @media (max-width: 800px) {
      width: 150px; // Adjust the size for smaller screens
    }
  }
`;

// Styled component for the details button
export const DetailsButton = styled.a`
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: auto;
  padding: 10px 30px;
  box-shadow: inset 0 0 0 2px #375a82;
  color: #28415e;
  background: none;
  height: 50px;
  border-radius: 3px;
  letter-spacing: 1px;
  transition: background 0.4s;

  &:hover,
  &:focus {
    background: #375a82;
    color: #fff;
  }
`;

// Details functional component
const Details = () => {
  const tiers = [
    {
      title: "BOOKING SYSTEM",
      description1:
        "The Dynamic Online Booking System is a cloud-based solution specifically designed for events, featuring an easy setup that allows you to accept bookings 24 hours a day. Tailored to your specific event, the Dynamic Booking System enables user registration and provides email ticketing with a copy of the QR code for easy access.",
      svg: bookingSystemSvg,
    },
    {
      title: "PLATFORM INTERFACE",
      description1:
        "Our platform boasts a user-friendly interface that ensures a seamless booking experience for users of all ages. With real-time availability tracking, you can manage bookings effortlessly and prevent overbooking. The system is fully responsive, allowing users to make reservations from any mobile device, ensuring accessibility wherever they are.",
      svg: platformInterfaceSvg,
    },
    {
      title: "BACKEND SERVER",
      description1:
        "Additionally, we offer comprehensive backend services to enhance your booking experience. Our analytics dashboard provides valuable insights into user behaviour, sales performance, and event trends, empowering you to make informed decisions. We prioritise data security and provide robust support to help you optimise your operations and enhance customer satisfaction.",
      svg: backendServerSvg,
    },
  ];

  return (
    <DetailsContainer>
      {tiers.map((tier, index) => (
        <Article key={index}>
          <SvgWrapper>
            <img src={tier.svg} alt={`${tier.title} icon`} />
          </SvgWrapper>
          <h3>{tier.title}</h3>
          <p>{tier.description1}</p>
        </Article>
      ))}
    </DetailsContainer>
  );
};

export default Details;
